/* Customize your main colors in :root variables */
:root {
  --mato-red: #f04949;
  --mato-dark-green: #2d5226;
  --mato-light-green: #7bb86d;
  --main-background-color: var(--mato-dark-green);
  --card-background-color: var(--mato-light-green);
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}

body{
  background-color:var(--main-background-color);
  /*background: url("img/background.png");
  background-size: cover;*/

  font-family: 'Patrick Hand', cursive;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



